/* eslint-disable */

import Card from "../../../components/card";
import Inbox from "./components/Inbox/Inbox";
import MessagesScreen from "./components/Messages/MessagesScreen";
import {ChattingProvider} from "./components/Context/Chatting/chatting";
import {useState} from "react";

export default function HelpAndSupport({...props}) {
    const [selectedInbox, setSelectedInbox] = useState(null)

    return (
        <ChattingProvider>
            <div className={"w-full h-[80vh]    mt-8 "}>
                <div className={" flex-1 flex  gap-2 flex-row"}>
                    <Inbox selectedInbox={selectedInbox} setSelectedInbox={setSelectedInbox}
                           className={`${selectedInbox !== null ? 'hidden md:flex' : ""}   md:flex-[2] lg:flex-[1] h-[80vh]`}/>
                    <MessagesScreen selectedInbox={selectedInbox} setSelectedInbox={setSelectedInbox} className={` ${selectedInbox === null ? 'hidden md:flex ' : ""} flex-[3] h-[80vh]`}/>
                </div>

            </div>
        </ChattingProvider>
    )
}