/* eslint-disable */
import React, {useContext, useEffect} from "react";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import Urls from "../../../../Url/urls";
import man from '../../../../assets/img/profile/man.png';

import {CURRENCY} from "../../../../other/AppLevelConfig";
import {Card} from "@chakra-ui/react";

function PaymentCard(props: { data: any }) {
    if (props.data == null || props.data?.payments.length === 0) {
        return <div className={"w-full grid grid-flow-row md:grid-flow-col font-bold  p-4"}>
            No Payment Data
        </div>
    }

    return (
        <div className={"w-[98%] grid shadow-inner rounded shadow-black-50 m-4 p-4"}>
            {
                props.data?.payments.map((payment: any, index: number) => {
                    return (
                        <div className={"justify-start col-span-1 border-b-2 border-dotted border-black-500 "}>
                            <div className={"text-black text-xl font-normal"}>Payment {index + 1}
                                <div className={"w-full grid grid-flow-row md:grid-flow-col font-bold  p-4"}>
                                    {
                                        payment?.payment_status === "PENDING" ?
                                            <div className={"text-black text-sm font-light"}>Package Not Assigned</div>:
                                            <div className={"text-black text-sm font-light"}>Package: {payment?.pkgTitle}</div>
                                    }
                                    <div className={"text-black text-sm font-light"}>Amount: {payment?.amount}</div>
                                    <div className={"text-black text-sm font-light"}>Status: {payment?.payment_status}</div>
                                    <div className={"text-black text-sm font-light"}>Date: {payment?.payment_date}</div>

                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default function SPProfile(props: { obj: any}) {
    const [data, setData] = React.useState<any>(null);

    const sessionData = useContext(AuthenticationContext);
    const fetchData = () => {
        let data = {
            "serviceProvider": props.obj?.id,
        }
        fetch(Urls.getServiceProviderData, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + sessionData.sessionData.token
            },
            body: JSON.stringify(data)
        }).then((response) => {
            console.log(response)
            return response.json();
        })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);

            })
    }
    useEffect(() => {
        fetchData();
    }, []);
    const [loading, setLoading] = React.useState<boolean>(true);
    return (
        <Card className={"w-full fade-anim mb-8 mt-8 h-auto rounded-lg dark:text-white"}>
        {/*   create a div which acts as two colum on big screens and rows on sm */}
            <div className={"w-full grid grid-flow-row md:grid-flow-col  p-4"}>
                <div className={"justify-start col-span-1 bg-red  "}>
                    <img
                        className={"w-96 h-auto rounded-lg"}
                        src={
                        props.obj?.user?.dp? Urls.mainDomain + props.obj?.user?.DP.file : man
                    } />
                </div>
                <div className={"justify-start col-span-6 md:justify-center "}>
                    <div className={"text-black flex flex-row items-center text-2xl font-bold"}>{props.obj?.user?.Name}
                        <div className={"text-sm flex flex-row shadow-lg p-2 shadow-black-50 text-white rounded-2xl bg-purple-600 font-semibold ml-5"}>
                            <div className={"text-sm font-normal mr-1"}>
                            Status:
                            </div>
                                {props.obj?.is_verified ? " Active" : " Inactive"}
                        </div>
                    </div>
                    <div className={"text-black text-md font-normal"}>Date of Birth: {props.obj?.user?.dob?
                        props.obj?.user?.dob : "Not Available"
                    }</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.email}</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.phone}</div>
                    <div className={"text-black text-md font-normal"}>Date Joined: {props.obj?.user?.date_joined}</div>
                    <div className={"text-black text-md font-bold"}>Business</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.email}</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.phone}</div>

                </div>
            </div>
            <div className={"w-full grid grid-flow-row md:grid-flow-col  p-4"}>
                <div className={"justify-start col-span-1 md:justify-center mt-12 "}>
                    <div className={"text-black text-xl font-bold"}>Address</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.address?.address}</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.address?.city}</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.user?.address?.country}</div>
                    <div className={"text-black text-md font-normal"}>POSTAL {props.obj?.user?.address?.post_code}</div>
                </div>
                <div className={"justify-start col-span-1 md:justify-center mt-12 "}>
                    <div className={"text-black text-xl font-bold"}>Social Media Accounts</div>
                    {
                    //     map object keys and values

                        Object.keys(props.obj?.social_media_accounts).map((key, index) => {
                            if(props.obj?.social_media_accounts[key]==="" || props.obj?.social_media_accounts[key]===null
                            ||key==="id" || key==="user")
                                return null
                            
                            return (
                                <div className={"text-black text-md flex flex-row font-normal"}>
                                    -
                                     <a className={"ml-5 text-blue-500"} target="_blank" href={props.obj?.social_media_accounts[key]}>{key}</a></div>
                            )

                        })
                    }



                </div>
            </div>
            {/* create a horiziontal line with dashed border prominent */}
            <div className={"w-full border-b-2 border-dashed border-black-900"}></div>

            <PaymentCard data={data} />

            <div className={"w-full border-b-2 border-dashed border-black-900"}></div>

            <div className={"w-full p-4 text-black text-xl font-bold"}>Licenses
            <p className={"text-black text-sm font-light"}>(Click to View)</p>
            </div>


            <div className={"w-full grid grid-flow-row md:grid-flow-col  p-4"}>

                {
                    props.obj?.license_count>0?
                        props.obj?.licenses.map((license: any, index: number) => {
                            return (
                                <a target="_blank" href={props.obj?.user?.dp? Urls.mainDomain + props.obj?.user?.DP.file : ""} className={"justify-start col-span-1 bg-red cursor-pointer  "}>
                                    <img
                                        className={"w-60 h-auto rounded-lg"}
                                        src={
                                            props.obj?.user?.dp? Urls.mainDomain + props.obj?.user?.DP.file : ""
                                        } />
                                </a>
                            )
                } ):
                        <div className={"text-black text-sm font-normal"}>No Uploaded Licenses</div>
                }
            </div>
        </Card>
    )
}
