/* eslint-disable */
import React, {useContext, useEffect, useState} from "react";
import Card from "components/card";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import {FiSearch} from "react-icons/fi";
import {KDRUsers, memberships, ServiceProviders, services} from "../../../admin/tables/variables/tableDataColumns";
import Paginator from "../../../../components/table/Paginator";
import Urls from "../../../../Url/urls";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import man from '../../../../assets/img/profile/man.png';
import woman from '../../../../assets/img/profile/woman.png';
import {CURRENCY, userTypes} from "../../../../other/AppLevelConfig";
import {calculateDifference, getBackgroundColor, getCircularMarkerColor} from "../../common/functions";
import {HiInformationCircle, HiPencilAlt, HiTable, HiX} from "react-icons/hi";
import SwitchField from "../../../../components/fields/SwitchField";
import successNotification, {errorNotification} from "../../../../other/MakeNotication";
import {convertDate} from "../../../../other/extraFuns";
import {RxCross2} from "react-icons/rx";
import {AiOutlineCheck} from "react-icons/ai";


function ServiceTable(props: {  title: string, viewTrigger?: any, updatePatient?: any }) {


    const sessionData = useContext(AuthenticationContext);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const updateStatus = (id: number, action: string) => {
        let data = {
            service_id: id,
            action: action
        }
        fetch(Urls.changeServiceStatus, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "token " + sessionData.sessionData.token,
            },
            body: JSON.stringify(data)
        }).then((response) => {

            return response.json();
        }).then((json) => {
            if (json.success) {
                successNotification("Success", json.success)
                fetchData()
            }
            else {
                errorNotification("Error", "Something went wrong")
            }
        }).catch((err) => {
            console.log(err)
            errorNotification("Error", "Something s went wrong")


        })

    }
    const [activeImage, setActiveImage] = React.useState<number>(0);

    const columns = [
        columnHelper.accessor("title", {
            id: "name",
            header: () => (
                <p className="text-sm  font-bold text-gray-600 dark:text-white">Service</p>
            ),
            cell: (info: any) => (
                <p className="flex flex-row gap-2 items-center text-sm capitalize font-bold text-navy-700 dark:text-white">
                    <img alt={"dp"} className={"w-12  h-12"}
                         src={info.row.original.media ? info.row.original.media[0]?.file :  man}/>
                        <div>
                        <p className="font-bold text-md" > {info.getValue()} </p>
                        <p  className="font-light text-blue-500 text-sm cursor-pointer s" onClick={()=>{

                            let card = document.getElementById("details-"+info.row.original.id);
                            if(card){
                                card.classList.toggle("hidden")
                            }



                        }} > View description </p>
                            <Card id={"details-"+info.row.original.id} className={"absolute p-4 bg-lightPrimary dark:bg-gray-900 shadow-inner shadow-2xl hidden rounded z-10"}>
                                {/*    grid 2 cols */}
                                <div className={"grid grid-cols-2 gap-4 "}>

                                        <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original?.details}</p>


                                </div>
                            </Card>
                        </div>




                </p>
            ),
        }),
        columnHelper.accessor("userName", {
            id: "user",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Owner
                </p>
            ),
            cell: (info) => (
                <p className="text-sm text-navy-700 dark:text-white">
                    <p className="font-bold text-md" > {info.getValue()} </p>
                    {info.row.original.email}
                </p>
            ),
        }),
        columnHelper.accessor("price", {
            id: "price",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    PRICE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()} {CURRENCY}

                </p>
            ),
        }),
        columnHelper.accessor("service_address.country", {
            id: "address",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">ADDRESS</p>
            ),
            cell: (info) => (
                <p onClick={()=>{
                    // on popup show card
                    //    get card by id
                    let card = document.getElementById("address-"+info.row.original.id);
                    if(card){
                        card.classList.toggle("hidden")
                    }



                }} className=" grid grid-cols-2 text-sm font-bold text-navy-700 dark:text-white cursor-pointer">
                    {info.row.original?.service_address?.city+", "+info.getValue()}
                    <HiInformationCircle className={"w-6 h-6"}/>
                    <Card id={"address-"+info.row.original.id} className={"absolute p-4 bg-lightPrimary dark:bg-gray-900 shadow-inner shadow-2xl hidden rounded z-10"}>
                        {/*    grid 2 cols */}
                        <div className={"grid grid-cols-2 gap-4 "}>
                            <div>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>STREET</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>STATE</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>CITY</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>COUNTRY</p>

                            </div>
                            <div>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original?.service_address?.streetAddress}</p>

                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original?.service_address?.state}</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original?.service_address?.city}</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original?.service_address?.country}</p>

                            </div>

                        </div>
                    </Card>
                </p>
            ),
        }),
        columnHelper.accessor("is_approved", {
            id: "action",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    ACTION
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {
                        info.getValue() ?(<button
                                onClick={() => {
                                    updateStatus(info.row.original.id, "reject")
                                }}
                                className={"bg-red-500 hover:bg-red-700 text-sm text-white font-bold py-2 px-4 flex justify-center rounded-3xl"}>
                                {/* cross icon to cancel  */}
                                <RxCross2 className={"h-4 w-4"}/>
                                | Make Inactive

                            </button>)
                            :
                            (
                                <button
                                    onClick={() => {
                                        updateStatus(info.row.original.id, "approve")
                                    }}
                                    className={"bg-green-500 hover:bg-green-700 text-sm text-white flex justify-center font-bold py-2 px-4 rounded-3xl"}>
                                    <AiOutlineCheck className={"h-4 w-4"}/>
                                    | Make Active
                                </button>
                            )
                    }

                </p>
            ),
        }),




    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => []);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    const [query, setQuery] = React.useState({
        page: 1,
        page_size: 4,
        query: "",
    });
    const [paginatorQuery, setPaginatorQuery] = useState({
        page: 1,
        next: false,
        previous: false,
    })

    function fetchData() {
        let data = `page=${query.page}&page_size=${query.page_size}&name=${query.query}`;
        fetch(Urls.allservices + "?" + data, {
            method: "GET",
            headers: {
                Authorization: "token " + sessionData.sessionData.token,
            }
        }).then((response) => {
            return response.json();
        }).then((json) => {
            if (json) {
                setData(json.results);
                console.log(json.results)
                for (let i in json.results){
                    // if (json.results[i].user.name !== null){
                    //     json.results[i].user.dpUrl = Urls.mainDomain + json.results[i].user.dpUrl
                    // }
                    console.log(json.results[i])
                }
                setPaginatorQuery({
                    page: query.page, next: !!json.next, previous: !!json.previous

                })
                console.log(json)

            }

        })

    }


    useEffect(() => {
        fetchData();
    }, [query])


    return (
        <Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl capitalize font-bold text-navy-700 dark:text-white">
                    {props.title.toLowerCase()} data
                </div>
                <div
                    className="flex h-full p-2 items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white"/>
                    </p>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => {
                            setQuery({...query, query: e.target.value, page: 1});
                        }}
                        className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                </div>

            </header>

            <div className="mt-8 overflow-x-auto">
                <table className="w-full">
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="!border-px !border-gray-400">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                                    >
                                        <div className="items-center justify-between text-xs text-gray-200">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: "",
                                                desc: "",
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table
                        .getRowModel()
                        .rows
                        .map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                key={cell.id}
                                                className="min-w-[150px] border-white/0 py-3  pr-4"
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Paginator
                page={paginatorQuery.page}
                next={paginatorQuery.next}
                previous={paginatorQuery.previous}
                onPageChange={(page) => {
                    setQuery({
                        ...query,
                        page: page,
                    })
                }
                }
            />
        </Card>
    );
}

export default ServiceTable;
const columnHelper = createColumnHelper<services>();
