/* eslint-disable */

import {DEVELOPER_CONTACT, DEVELOPER_NAME} from "../../other/constString";
import React from "react";
import {AuthenticationContext} from "../../context/authenticationContext";

const Footer = () =>
{
    const AuthContext = React.useContext(AuthenticationContext);
    const {sessionData} = AuthContext;
    return (
        <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
            <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
                <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
                    {
                         sessionData.profile.roles!==null?
                             sessionData.profile.roles?.includes("SUPER_ADMIN") ?
                            "Super Admin Portal " :
                            sessionData.profile.roles?.includes("STAFF") ?
                                "Staff Portal " :
                                null
                             :
                                null

                    }
                    | ©{new Date().getFullYear()} All Rights Reserved.
                </p>
            </h5>
            <div>
                <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
                    <li>
                        <a
                            target="blank"
                            href={"tel:" + DEVELOPER_CONTACT}
                            className="text-base font-medium text-gray-600 hover:text-gray-600"
                        >
                            Need Help?
                        </a>
                    </li>


                </ul>
            </div>
        </div>
    );
};

export default Footer;
