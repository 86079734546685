import {AiOutlineSend} from "react-icons/ai";
import {useContext, useState} from "react";
import ChattingContext from "../Context/Chatting/chatting";

function SendButton({...props}) {


    return (
        <button
            className={"w-10 h-10 rounded-full bg-brand-500 hover:bg-brand-600 text-white flex justify-center items-center"}
            {...props}
        >
            <AiOutlineSend className={"w-4 h-4"}/>

        </button>
    )
}


export default function Footer({className, selectedInbox,setSelectedInbox, ...props}) {

    const InboxContext = useContext(ChattingContext);

    const [message, setMessage] = useState("");


    return (
        <div
            className={`rounded-full m-4 flex flex-row gap-2  px-6 py-2 dark:border-navy-700 border-[1px]  shadow-lg ${className}`}>

            <input
                placeholder={"Type a message"}
                value={message}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        if (message.trim().length === 0) return;
                        InboxContext.sendMessageTo(selectedInbox, {
                            recipient: selectedInbox,
                            content: message.trim(),
                        });
                        setMessage("");
                    }
                }}
                onChange={(e) => setMessage(e.target.value)}
                className={"flex-1 bg-transparent dark:text-white focus:outline-none"}

            />

            <SendButton
                onClick={() => {

                    if (message.trim().length === 0) return;
                    InboxContext.sendMessageTo(selectedInbox, {
                        recipient: selectedInbox,
                        content: message.trim(),
                    });
                    setMessage("");
                }}

            />
        </div>
    )
}