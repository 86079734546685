interface PatientDoctor {
    id: number,
    user: {
        id: number;
        email: string;
        roles: string;
        Full_Name: string;
        dp: string;
        gender: string;
        dob: string;
        age: number;
        phone: string;
    };
    location: {
        id: number;
        address: string;
        longitude: number;
        latitude: number;
    },


    last_seen: string;
    last_visited_in_days: any;
    actions: any;
    status?: any;
    priority?: any;

};
interface ServiceProviders {
    id: number,
    user: {
        id: number;
        email: string;
        roles: string;
        Name: string;
        dp: string;
        dob: string;
        dpUrl: string;
        date_joined?: string;
        is_active?: boolean;
        address: {
            address: string;
            country: string;
            state: string;
            city: string;
            post_code: string;
        }
    };
    business_name: string;
    business_category: string;
    license_count: number;
    social_media_account_count: number;
    licenses:object[];


    is_verified: boolean;
    actions: any;
    status?: any;
    priority?: any;

};

interface KDRUsers {
    id: number;
    email: string;
    roles: string;
    Name: string;
    dp: string;
    address: {
        address: string;
        country: string;
        state: string;
        city: string;
        post_code: string;
    }
    dob: string;
    DP : {
        file : string;
        fileUri : string;

    }
    dpUrl: string;
    date_joined?: string;
    is_active?: boolean;

};
interface memberships {
    id: number;
    userObj: KDRUsers;
    packageObj: {
        id: number;
        title: string;
    }
    currentStatus?: string;
    actions?: any;
    status?: any;
    is_active?: boolean;
    start_date?: string;
    end_date?: string;
    amount?: number;
    package?:any;

};
interface promotions {
    id: number;
    title: KDRUsers;
    description: any;
    promotion_type?: string;
    image?: string;
    status?: any;
    action?:any


};
interface payments {
    id: number;
    user: KDRUsers;
    subscription: any;
    payment_status?: string;
    payment_id?: string;
    actions?: any;
    status?: any;
    is_active?: boolean;
    start_date?: string;
    end_date?: string;
    amount?: number;
    package?:any;

};

interface services {
    id: number;
    title: string;
    media?:object[];
    details: string;
    userName: string;
    email: string;
    category: {
        title: string;
    };
    sub_category: {
        title: string;
    };
    service_address: {
        streetAddress: string;
        city: string;
        state: string;
        country: string;
    }
    price?: string;
    actions?: any;
    is_approved?: string;
    status?: any;
    is_active?: boolean;
    start_date?: string;
    end_date?: string;
    amount?: number;
    package?:any;

};
interface Route {
    id: number,
    title :any,
    zone :any,
    zoneName :any,
    total_distance :any,
    total_time :any,
    created_at :any,
    updated_at :any,
    doctors : number
    doctorObj:PatientDoctor,
    date_of_visit :any,
    actions: any,
    selectedPatients: any,


};
interface Staff {
    id: number;
    email: string;
    roles: string;
    Full_Name: string;
    dp: string;
    dpUrl: string;
    gender: string;
    dob: string;
    phone: string;
    age: number;
    last_seen: string;

};
// {
//     name: 'Horizon UI PRO',
//         quantity: 2458,
//     progress: '17.5%',
//     date: '12 Jan 2021',
// },
const tableDataColumns: PatientDoctor[] = [
    {
        "id": 1,
        actions: null,
        "user": {
            "id": 4,
            "email": "ali1@gmail.com",
            "roles": "PATIENT",
            "Full_Name": "asda",
            "dp": null,
            "gender": "MALE",
            "dob": "2023-03-16",
            "phone": "4234234234",
            "age": 0,

        },
        "location": {
            "id": 1,
            "address": "814 Street 18",
            "longitude": -103.20103880584898,
            "latitude": 39.42715390380869
        },
        "last_seen": "29 May 2020",
        "last_visited_in_days": 0

    },
    {
        "id": 2,
        actions: null,
        "user": {
            "id": 5,
            "email": "ali13@gmail.com",
            "roles": "PATIENT",
            "Full_Name": "asda",
            "dp": null,
            "gender": "MALE",
            "dob": "2023-03-16",
            "phone": "4234234234",
            "age": 0,



        },
        "location": {
            "id": 2,
            "address": "814 Street 18",
            "longitude": -101.30803880584898,
            "latitude": 32.52715390380869
        },
        "last_seen": "29 May 2020",
        "last_visited_in_days": 0

    },


];

export default tableDataColumns;

export type {
    PatientDoctor,
    ServiceProviders,
    KDRUsers,
    Route,
    services,
    Staff,
    payments,
    memberships,
    promotions
};
