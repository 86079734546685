/* eslint-disable */
import React, {useContext, useEffect, useState} from "react";
import tableDataColumns from "../../admin/tables/variables/tableDataColumns";
import {HiBookmark, HiUserAdd} from "react-icons/hi";
import {BsEyeFill} from "react-icons/bs";
import {getCookie} from "../common/functions";
import ServiceCard from "./components/ServiceCard";
import InputField from "../../../components/fields/InputField";
import Urls from "../../../Url/urls";
import {AuthenticationContext} from "../../../context/authenticationContext";
import ServiceTable from "./components/ServiceTable";


export default function Services() {

    return (
        <div className={"w-full mt-8"}>
            {/**/}


            <ServiceTable title={"All Services"} />





        </div>
    )
}