/* eslint-disable */
import React, {useEffect} from "react";
import tableDataColumns from "../../admin/tables/variables/tableDataColumns";
import {HiBookmark, HiUserAdd} from "react-icons/hi";
import {BsEyeFill} from "react-icons/bs";
import {getCookie} from "../common/functions";
import ServiceProviderTable from "../ServiceProviders/components/ServiceProviderTable";
import MembershipsTable from "./components/MembershipsTable";


export default function Memberships() {
    const [updateData, setUpdateData] = React.useState<object>(null);
    return (
        <div className={"w-full mt-8"}>
            <div className={"w-full flex justify-end mt-4 mb-4"}>

            </div>

            <MembershipsTable  title={"All Memberships"} />


        </div>
    )
}