import {useContext, useState} from "react";
import {Spinner} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import Card from "components/card";
import URLS from "../../../../Url/urls";
import {successNotify} from "../../../../other/extra";
import axios from "axios";
import {AuthenticationContext} from "../../../../context/authenticationContext";


const PasswordChange = () => {
    const [form, setForm] = useState({
        old_password: "",
        new_password: "",
        confirmPassword: ""
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);
    const session = useContext(AuthenticationContext);


    function Submit() {

        if (form.new_password !== form.confirmPassword) {
            setError({
                new_password: ["Password does not match"]
            })
            return;
        }
        setError(null);
        setLoading(true);
        axios.post(URLS.ChangePassword, form, {
            headers: {
                Authorization: "token " + session.sessionData.token
            }
        }).then((res) => {
            if (res.status <= 201) {
                setForm({
                    old_password: "",
                    new_password: "",
                    confirmPassword: ""
                })
                successNotify("Password Changed", "You have successfully changed your password")

            }
        }).catch((err) => {
            if (err.response.status === 400) {
                setError(err.response?.data);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Card extra={"items-center mt-4 p-[16px] bg-cover"}>
            <p className="text-lg font-bold">Change Password</p>
            <p className="mt-2 text-base text-gray-600">Here you can change your Password</p>
            <div className={"w-full p-4"}>
                <InputField
                    extra="flex-1 mb-3"

                    id={"1"}
                    variant={""}
                    label="Old Password*"
                    name="Old Password*"
                    error={error?.old_password}
                    value={form.old_password}
                    required
                    onChange={(e) => {
                        setForm({
                            ...form,
                            old_password: e.target.value
                        })
                    }}
                    placeholder="Min. 8 characters"
                    type="password"
                />
                <InputField
                    extra="flex-1 mb-3"
                    label="new Password*"
                    name="new Password*"
                    id={"2"}
                    variant={""}
                    error={error?.new_password}
                    value={form.new_password}
                    required
                    onChange={(e) => {
                        setForm({
                            ...form,
                            new_password: e.target.value
                        })
                    }}
                    placeholder="Min. 8 characters"
                    type="password"
                />
                <InputField
                    extra="flex-1 mb-3"
                    id={"3"}
                    variant={""}
                    label="Confirm Password*"
                    name="Confirm Password*"
                    error={error?.new_password}
                    value={form.confirmPassword}
                    required
                    onChange={(e) => {
                        setForm({
                            ...form,
                            confirmPassword: e.target.value
                        })
                    }}
                    placeholder="Min. 8 characters"
                    type="password"
                />


            </div>
            <button
                onClick={Submit}
                disabled={loading}
                className="linear  w-1/3 self-end rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                {
                    loading ? <Spinner className={"w-8 h-8"}/> : "Change Password"
                }
            </button>
        </Card>
    )
}


export default PasswordChange;