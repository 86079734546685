/* eslint-disable */
const IS_DEV = false

var Ip = ""
var MainDomain = null
var Domain = null
var WsUrl = null

if (IS_DEV) {
    Ip = "adminapi.konnectingretailers.com"
    Domain = `https://${Ip}`
    WsUrl = `ws://kdr.konnectingretailers.com:8000/`



} else {
    Ip = "adminapi.konnectingretailers.com"
    Domain = `https://${Ip}`
    WsUrl = `wss://kdr.konnectingretailers.com:9001/`



}
const Urls = {
    login: Domain + "/login",
    images: Domain+"/",
    PatientDoctorCreate: Domain + "/PatientDoctorCreate",
    getServiceProviderData: Domain + "/getServiceProviderData",
    withdrawAmount: Domain + "/withdrawAmount",
    allmemberships: Domain + "/allmemberships",
    PromotionCreateUpdate: Domain + "/PromotionCreateUpdate",
    allpromotions: Domain + "/allpromotions",
    allPayments: Domain + "/allPayments",
    allservices: Domain + "/allservices",
    allClients: Domain + "/allClients",
    allserviceProviders: Domain + "/allserviceProviders",
    getRoute: Domain + "/getRoute",
    dashboard: Domain + "/dashboard",
    uploadFile: Domain + "/uploadFile",
    createRoute: Domain + "/createRoute",
    wallet: Domain + "/wallet",
    updateRoute: Domain + "/updateRoute",
    updateRoutePStatus: Domain + "/updatePatientStatus",
    changeServiceStatus: Domain + "/changeServiceStatus",
    changeClientStatus: Domain + "/changeClientStatus",
    changeServiceProviderStatus: Domain + "/changeServiceProviderStatus",
    deleteRoute: Domain + "/deleteRoute",
    resetpassword: Domain + "/resetpassword",
    zone: Domain + "/zone",
    mediaUrl: Domain + "/media/",
    ForgePassword: Domain + "/password_reset",
    ValidateOtp: Domain + "/VerifyOtp",
    SetNewPassword: Domain + "/confirm_reset",
    mainDomain: Domain,
    ChangePassword: Domain+'/change-password',

}

export const WsUrls = {
    Chatting: WsUrl + "ws/socket-server/Chatting",
}

export  const ChattingURLs = {
    getInbox: Domain + "/get-inbox",
    getOldMessages: Domain + "/get-old-chats",

}



export default Urls;
