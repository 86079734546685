export default function SenderMessage({className, text, time, ...props}) {


    return (
        <div className={`w-full flex  flex-row-reverse gap-2 ${className}`}>
            <div className={"max-w-4/5 lg:max-w-[50%] bg-brand-500  rounded-b-3xl rounded-tl-3xl p-3"}>
                <p className={"text-gray-300 text-sm break-words"}>
                    {text}
                </p>

                <p className={"text-xs mt-3 text-gray-100"}>
                    {time}

                </p>
            </div>
        </div>
    )
}