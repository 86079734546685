/* eslint-disable */
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import Widget from "../../../components/widget/Widget";
import {MdBarChart, MdDashboard} from "react-icons/md";
import {IoDocuments} from "react-icons/io5";
import {GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import {CURRENCY, GOOGLE_MAPS_API_KEY} from "../../../other/AppLevelConfig";
import getPolygonCenter, {DEFAULT_CENTER} from "../../../other/extra";
import generatedMapStyle from "../common/MapStyle";
import Polygone from "../../../components/Polygone/Polygone";
import PatientDoctorMarker from "../../../components/Markers/Marekers";
import {AuthenticationContext} from "../../../context/authenticationContext";
import Urls from "../../../Url/urls";
import {errorNotification} from "../../../other/MakeNotication";
import {GiPersonInBed} from "react-icons/gi";
import ViewZone from "../Zone/ViewZone";


export default function KDRDashboard() {
    const {isLoaded} = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: GOOGLE_MAPS_API_KEY
        }
    );
    const sessionContext = React.useContext(AuthenticationContext);
    const options = useMemo(
        () => ({
            //<MapOptions>
            disableDefaultUI: true,
            clickableIcons: true,
            styles: generatedMapStyle,
        }),
        []
    );

    const mapRef = useRef(); //mapRef: <GoogleMap>
    const onLoad = useCallback((map: any) => {

        mapRef.current = map;
    }, []);
    const [data, setData] = React.useState<any>({
        "patientCount": 0,
        "doctorCount": 0,
        "staffCount": 0,
        "zoneCount": 0,
        "zone": [],
        "clients": []
    });

    function fetchData() {
        fetch(Urls.dashboard, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `token ${sessionContext.sessionData.token}`
            }
        }).then((response) => response.json()).then((data) => {
            setData(data);
        }).catch((error) => {
            errorNotification("Error", "Something went wrong");
            console.log(error);

        })
    }

    useEffect(() => {
        fetchData();
    }, [])


    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className={"mt-4"}>

            {/*    a key to view map
    blue means never visited
    green means visited in last week
    orange means visited in last month
    red means visited more than a month ago

        */}



            <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4"}>
                <Widget title={"Clients"} icon={<GiPersonInBed className={"h-6 w-6"}/>}
                        subtitle={data.total_clients}/>
                <Widget title={"Service Providers"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                    subtitle={data.total_service_providers}/>
                <Widget title={"Pending Service Providers"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                        subtitle={data.pending_service_providers}/>

                <Widget title={"Services"} icon={<GiPersonInBed className={"h-6 w-6"}/>}
                        subtitle={data.total_services}/>
                <Widget title={"Categories"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                        subtitle={data.total_categories}/>
                <Widget title={"Sub Categories"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                        subtitle={data.total_sub_categories}/>

                <Widget title={"Payments Made"} icon={<GiPersonInBed className={"h-6 w-6"}/>}
                        subtitle={data.total_payments}/>
                <Widget title={"Withdrawal Amount"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                        subtitle={`${data.withdrawal_amount} ${CURRENCY}`}/>
                <Widget title={"Active Memberships"} icon={<GiPersonInBed className={"h-6 w-6"} />}
                        subtitle={data.total_active_subscriptions}/>
            </div>






        </div>
    )
}
