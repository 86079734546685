const App_Name = "LUNA TECH";
const App_Version = "1.0.0";
const DEVELOPER_NAME = "LUNA TECH";
const FIRM_NAME = "Lunatech";
const FIRM_PUNCH_LINE = "Your Tech Partner";
const DEVELOPER_CONTACT = "+923485667881"
const DEVELOPER_EMAIL = "adeelcyber077@gmail.com"
const DEVELOPER_WEBSITE = "https://lunatech.pk"
const DEVELOPER_ADDRESS = "Islamabad, Pakistan"

const  HELP_AND_SUPPORT_EMAIL = "info@konnectingretailers.com"

export default App_Name;
export {App_Version, DEVELOPER_NAME, DEVELOPER_CONTACT, DEVELOPER_EMAIL, DEVELOPER_WEBSITE,FIRM_NAME, DEVELOPER_ADDRESS, FIRM_PUNCH_LINE, HELP_AND_SUPPORT_EMAIL};