/* eslint-disable */
import InboxComponent from "./InboxComponent";
import SearchInput from "./SearchInput";
import {useContext, useEffect, useState} from "react";
import {AuthenticationContext} from "../../../../../context/authenticationContext";
import ChattingContext from "../Context/Chatting/chatting";
import {ChattingURLs} from "../../../../../Url/urls";
import {getUrl} from "../../../../../other/extra";
import {HELP_AND_SUPPORT_EMAIL} from "../../../../../other/constString";

export default function Inbox({className,selectedInbox,setSelectedInbox, ...props}) {


    const session = useContext(AuthenticationContext);
    const chattingContext = useContext(ChattingContext);
    const [query, setQuery] = useState("")


    let fetchInbox = async (page, query, emptyPrevious = false) => {
        let requestData = {}
        if (page && page > 0) {
            requestData.page = page
        }
        if (query && query.length > 0) {
            requestData.query = query
        }
        if (emptyPrevious) {
            chattingContext.setInbox({})
        }


        await fetch(ChattingURLs.getInbox, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "token " + session.sessionData.token
            },
            body: JSON.stringify(requestData)
        }).then(response => response.json()
        ).then(data => {

            if (data.inbox) {

                let inbox = chattingContext.inbox
                for (let key in data.inbox) {
                    if (inbox[key]) {
                        delete data.inbox[key]
                    }
                }
                inbox = {...inbox, ...data.inbox}
                chattingContext.setInbox(inbox)
            }
            if (data.hasNext) {
                fetchInbox(page + 1, query)
            }

        }).catch(err => {

        })
    }
    useEffect(() => {
        if (session.sessionData.token) {

            fetchInbox(1, query, true)
        }
    }, [query])

    useEffect(() => {
        // onFocusFetchInbox()
        // navigation.addListener('focus', () => {
        //     if (session.session.token) {
        //         fetchInbox(1,"",true)
        //     }
        // })


    }, [session.sessionData.token])


    return (
        <div
            className={`w-full bg-white dark:bg-navy-800 flex flex-col gap-2 p-1 rounded-xl shadow-md  overflow-auto   ${className}`}>
            <p className={"text-black dark:text-gray-300 text-lg m-2 font-bold"}>
                Your Chats
            </p>
            <SearchInput  value={query}
                          onChange={(e)=>{
                                setQuery(e.target.value)
                          }}


                          />

            <div className={"flex-1 flex flex-col gap-2 overflow-auto px-4 "}>

                {
                    Object.keys(chattingContext.inbox).map((key, index) => {
                        let email = key;
                        const messages = chattingContext?.inbox[email]?.messages ? chattingContext?.inbox[email].messages : [];
                        let   uri = null;
                        if (messages?.[0]?.senderInfo.email !== session.sessionData.profile.email && messages?.[0]?.senderInfo.email !== HELP_AND_SUPPORT_EMAIL) {
                            uri = getUrl(messages?.[0]?.senderInfo.image)
                        }else {
                            uri = getUrl(messages?.[0]?.recipientInfo.image)
                        }




                        return (
                            <InboxComponent key={index} name={chattingContext.inbox[key].Name}
                                            message={chattingContext.inbox[key]?.messages[0]?.content}
                                            onClick={()=>{
                                                setSelectedInbox(key)
                                            }}
                                            image={uri.uri}
                                            time={chattingContext.inbox[key]?.messages[0]?.time} email={key}/>
                        )
                    })
                }


            </div>

        </div>
    )
}