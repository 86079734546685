export default function InboxComponent({className, name, time, image,email, message, onClick, ...props}) {


    return (
        <div
            onClick={onClick}
            className={`w-full bg-white   hover:bg-gray-50 cursor-pointer dark:bg-navy-800 flex border-b-gray-300 border-b-[1px]  gap-2 r pb-4    ${className}`}>

            <img src={image}
                 alt={name}
                 className={'w-8 h-8 rounded-full '}

            />
            <div className={"flex-1 flex flex-col gap-1"}>
                <div className={"flex flex-row"}>
                    <div className={"flex-1 flex flex-col"}>
                        <p className={"font-bold dark:text-gray-300 flex-1"}>
                            {name}
                        </p>

                    </div>

                    <p className={"text-xs text-gray-400"}>
                        {time}
                    </p>
                </div>
                <p className={"text-sm  text-gray-400"}>
                    {message?.length > 15 ? message.substring(0, 15) + "..." : message}
                </p>


            </div>


        </div>
    )
}