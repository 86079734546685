import {AiOutlineSearch} from "react-icons/ai";

export default function SearchInput({className, ...props}) {


    return (
        <div
            className={`w-full flex justify-center items-center gap-2 mb-4 mt-2 dark:border-gray-800 border-[1px]  flex-row  bg-white dark:bg-navy-800 rounded-full px-4 py-2 shadow-md  ${className}`}>
            <AiOutlineSearch className={"text-gray-300"}/>
            <input
                className={"flex-1 focus:outline-none dark:text-white bg-transparent"}
                placeholder={"Search"}
                {...props}

            />
        </div>
    )

}