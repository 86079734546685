export function getMonth(mm: string) {
    let m = parseInt(mm)
    switch (m) {
        case 1:
            return "Jan"
        case 2:
            return "Feb"
        case 3:
            return "Mar"
        case 4:
            return "Apr"
        case 5:
            return "May"
        case 6:
            return "Jun"
        case 7:
            return "Jul"
        case 8:
            return "Aug"
        case 9:
            return "Sep"
        case 10:
            return "Oct"
        case 11:
            return "Nov"
        case 12:
            return "Dec"

    }
}
export function convertDate(date: string) {
    let yyyy = date.split("-")[0];
    let mm = date.split("-")[1];
    let dd = date.split("-")[2];
    return `${getMonth(mm)} ${dd}, ${yyyy}`

}