import {Store} from "react-notifications-component";
import Urls from "../Url/urls";

export default function getPolygonCenter(coords) {
    if (coords === undefined) return {
        lat: 37.7749,
        lng: -122.4194,
    };
    if (coords.length === 0) return {
        lat: 37.7749,
        lng: -122.4194,
    };
    let lat = 0;
    let lng = 0;
    coords.forEach(coord => {
        lat += coord.lat;
        lng += coord.lng;
    });
    return {
        lat: lat / coords.length,
        lng: lng / coords.length
    };
}
const DEFAULT_CENTER = {
    lat: 37.7749,
    lng: -122.4194,
}
const COLORS = ["red", "#e1850d", "green"]
function RandomColor() {

    return COLORS[Math.floor(Math.random() * COLORS.length)]
}


export function successNotify(title, message) {
    Store.addNotification({
        title: title,
        message: message,
        type: "default",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],

        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

export function errorNotify(title, message) {
    Store.addNotification({
        title: title,
        message: message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],

        dismiss: {
            duration: 2000,
            onScreen: true
        }
    });
}
export function getUrl(uri) {


    try {
        if (uri === null || uri === undefined) {
            return {
                uri: Urls.images + "media/default.png"
            };
        }
        if (uri.startsWith("/")) {
            uri = uri.substring(1);
        }

        return uri.includes("http") ?
            {
                uri: uri
            } :
            {
                uri: Urls.images + uri
            }
    }catch (E){
        return {
            uri: Urls.images + "default.png"
        }
    }


}
export function JsonToQueryString(json) {
    // remove null values
    Object.keys(json).forEach(function (key) {
        if (json[key] === null || json[key] === undefined) {
            delete json[key];
        }
    });
    return '?' +
        Object.keys(json).map(function (key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
}
export {
    DEFAULT_CENTER,
    RandomColor,
    COLORS
}