/* eslint-disable */
import React, {useEffect} from "react";
import UsersTable from "./components/UsersTable";
import UserProfile from "./components/Profile";


export default function Patient(props: {
    userType: string
}) {
    const [selected, setSelected] = React.useState<object>(null);
    const [viewTrigger, setViewTrigger] = React.useState<boolean>(false);
    useEffect(() => {
        if(selected !==null){
            // alert(JSON.stringify(selected))
            setViewTrigger(true)
        }

    }, [selected]);
    return (
        <div className={"w-full mt-8"}>
            <div className={"w-full flex justify-end mt-4 mb-4"}>
                {
                    viewTrigger ?
                        <div
                            onClick={() => {
                                setViewTrigger(!viewTrigger)
                                setSelected(null)
                            }}
                            className={"w-[60px] h-10 bg-brand-400 text-white flex cursor-pointer items-center justify-center  rounded-full shadow-2xl  dark:bg-navy-500"}>
                            {
                                !viewTrigger ?
                                    null :
                                    "BACK"
                            }

                        </div>:
                        null
                }
            </div>



            {!viewTrigger ?
                <UsersTable setSelected={setSelected} viewTrigger={setViewTrigger}  title={props.userType}/>:
                <UserProfile obj={selected}  />
            }

        </div>
    )
}