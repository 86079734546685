/* eslint-disable */
import React, {useContext, useEffect} from "react";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import Urls from "../../../../Url/urls";
import {CURRENCY} from "../../../../other/AppLevelConfig";

export default function WalletCardOld() {
    const [wallet, setWallet] = React.useState<any>({
        amount: 0
    });
    const sessionData = useContext(AuthenticationContext);
    const fetchWallet = () => {
        fetch(Urls.wallet, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + sessionData.sessionData.token
            }
        }).then((response) => {
            return response.json();
        })
            .then((data) => {
                setWallet(data.wallet);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);

            })
    }
    useEffect(() => {
        fetchWallet();
    }, []);
    const [loading, setLoading] = React.useState<boolean>(true);

    return (
        <div className={"w-full fade-anim mb-8 mt-8 h-[200px] rounded-lg bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500"}>
            <div className={"w-full flex justify-between items-center p-4"}>
                <div className={"flex md:ml-[200px] flex-col"}>
                    <div className={"text-white text-lg font-bold"}>Wallet</div>
                    <div className={"text-white text-[80px]"}>{
                        `${wallet?.amount}${CURRENCY}`
                    } </div>
                </div>
                <div className={"flex md:visible sm:invisible flex-col mr-[350px]"}>
                    {/*        image of wallet*/}
                    <img src={require("../../../../assets/img/dashboards/wallet.png")} className={"w-[200px] h-[200px]"}/>
                </div>
            </div>

            {/*    create a column with half screen size*/}



        </div>
    )
}