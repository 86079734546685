/* eslint-disable */
import SenderMessage from "./SenderMessage";
import ReceiverMessage from "./ReceiverMessage";
import ChattingContext from "../Context/Chatting/chatting";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthenticationContext} from "../../../../../context/authenticationContext";
import {getUrl, JsonToQueryString} from "../../../../../other/extra";
import {HELP_AND_SUPPORT_EMAIL} from "../../../../../other/constString";
import {ChattingURLs} from "../../../../../Url/urls";

export default function Body({className, selectedInbox, setSelectedInbox, ...props}) {
    let email = selectedInbox;
    const InboxContext = useContext(ChattingContext);
    const messages = InboxContext?.inbox[email]?.messages ? InboxContext?.inbox[email].messages : [];
    const session = useContext(AuthenticationContext);
    const [isFetchingOldMessages, setIsFetchingOldMessages] = useState(false)
    const [notMoreOldMessages, setNotMoreOldMessages] = useState(false)
    const [isNotAtBottom, setIsNotAtBottom] = useState(false)
    let fetchOldMessages = async (email, pageURL) => {
        if (isFetchingOldMessages) return;
        if (notMoreOldMessages) return;
        setIsFetchingOldMessages(true)
        let lastMessageId = messages.length > 0 ? messages[0].id : null;

        let params = {
            email: email,
            last_messaage_id: lastMessageId
        }
        let url = ChattingURLs.getOldMessages + JsonToQueryString(params)
        if (pageURL) {
            url = pageURL
        }
        await fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Token " + session.sessionData.token
            }
        }).then(response => response.json()
        ).then(data => {
            if (data.results) {
                InboxContext.addOldMessagesToInboxWith(email, data.results)
                if (data.results.length === 0) {
                    setNotMoreOldMessages(true)
                }
            }
            setIsFetchingOldMessages(false)
            // if (data.next) {
            //     fetchOldMessages(data.next)
            // }
        }).catch(err => {
            setIsFetchingOldMessages(false)
        })
    }


    const handleScroll = (e) => {
        // console.log("top",e.target.scrollTop+e.target.clientHeight)
        // console.log("Height",e.target.scrollHeight)
        // console.log("total", (e.target.scrollTop + e.target.clientHeight) - e.target.scrollHeight)
        let total = (e.target.scrollTop + e.target.clientHeight) - e.target.scrollHeight
        if (total >= 0 && total <= 100) {
            setIsNotAtBottom(false)
        } else {
            setIsNotAtBottom(true)
        }

        if (e.target.scrollTop <= 0) {
            fetchOldMessages(email);
        }
    }

    useEffect(() => {
        fetchOldMessages(email);
    }, [])

    const messagesEndRef = useRef();

    useEffect(() => {
        if (isNotAtBottom) return;

        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
        const scrollHeight = messagesEndRef?.current.scrollHeight;
        const height = messagesEndRef?.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }, [messages])


    return (
        <div onScrollCapture={handleScroll}
             ref={ref => messagesEndRef.current = ref}
             className={`w-full flex-col gap-4 md:gap-2 flex p-4 flex-1 overflow-auto ${className}`}>
            {messages.reverse().map((item, index) => {
                let nextItem = messages.length > index + 1 ? messages[index + 1] : null;
                let previousItem = index > 0 ? messages[index - 1] : null;
                let nextMine = nextItem !== null && messages[index].senderInfo.id === nextItem.senderInfo.id
                let previousMine = previousItem !== null && messages[index].senderInfo.id === previousItem.senderInfo.id
                if (item.senderInfo.email === session.sessionData.profile.email || item.recipient !== HELP_AND_SUPPORT_EMAIL) {

                    return <SenderMessage text={item.content} time={item.time} isNextMine={item?.wasNextMessageMine}
                                          isPreviousMine={item?.wasLastMessageMine} name={item?.senderInfo.Name}/>
                } else {
                    return <ReceiverMessage name={item.senderInfo.Name} isPreviousMine={item?.wasLastMessageMine}
                                            isNextMine={item?.wasNextMessageMine}
                                            nextMine={nextMine}
                                            text={item.content}
                                            dp={getUrl(item.senderInfo.image)}
                                            time={item.time}/>
                }


            })}

        </div>
    )
}