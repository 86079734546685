/* eslint-disable */
import React, {useEffect} from "react";
import tableDataColumns from "../../admin/tables/variables/tableDataColumns";
import ServiceProviderTable from "./components/ServiceProviderTable";
import {HiOutlinePlus} from "react-icons/hi";
import {RxCross2} from "react-icons/rx";
import SPProfile from "./components/SPProfile";


export default function ServiceProvider(props: {
    userType: string
}) {
    const [selected, setSelected] = React.useState<object>(null);
    const [viewTrigger, setViewTrigger] = React.useState<boolean>(false);
    useEffect(() => {
        if(selected !==null){
            setViewTrigger(true)
        }

    }, [selected]);
    return (
        <div className={"w-full mt-8"}>
            <div className={"w-full flex justify-end mt-4 mb-4"}>
                {
                    viewTrigger ?
                <div
                    onClick={() => {
                        setViewTrigger(!viewTrigger)
                        setSelected(null)
                    }}
                    className={"w-[60px] h-10 bg-brand-400 text-white flex cursor-pointer items-center justify-center  rounded-full shadow-2xl  dark:bg-navy-500"}>
                    {
                        !viewTrigger ?
                            null :
                            "BACK"
                    }

                </div>:
                        null
                }
            </div>
            {!viewTrigger ?
            <ServiceProviderTable tableData={tableDataColumns} viewTrigger={setViewTrigger} setSelected={setSelected}  title={props.userType}/>:
                <SPProfile obj={selected}  />
            }



        </div>
    )
}