/* eslint-disable */
import {IoIosArrowBack} from "react-icons/io";
import {useContext} from "react";
import ChattingContext from "../Context/Chatting/chatting";
import {AuthenticationContext} from "../../../../../context/authenticationContext";
import SenderMessage from "./SenderMessage";
import {getUrl} from "../../../../../other/extra";
import {HELP_AND_SUPPORT_EMAIL} from "../../../../../other/constString";

export default function Header({className, setSelected,email, ...props}) {
    const InboxContext = useContext(ChattingContext);

    const messages = InboxContext?.inbox[email]?.messages ? InboxContext?.inbox[email].messages : [];
    const session = useContext(AuthenticationContext);
    let name = null;
     let   dp = null;
    if (messages?.[0]?.senderInfo.email !== session.sessionData.profile.email && messages?.[0]?.senderInfo.email !== HELP_AND_SUPPORT_EMAIL) {
        name = messages?.[0]?.senderInfo.Name;
        dp = getUrl(messages?.[0]?.senderInfo.image)
    }else {
        name = messages?.[0]?.recipientInfo.Name;
        dp = getUrl(messages?.[0]?.recipientInfo.image)
    }


    return (
        <div className={"flex flex-row gap-2 p-4 border-b-[1px] border-gray-200 dark:border-navy-700"}>
            <IoIosArrowBack onClick={() => setSelected(null)}
                            className={"text-gray-400  md:hidden self-center text-xl dark:text-gray-300"}/>
            <img src={dp?.uri}
                 className={'w-8 h-8  md:w-12 md:h-12 rounded-full '}
            />
            <p className={"font-bold dark:text-gray-300  text-xs md:text-sm lg:text-lg"}>
                {name}
            </p>
        </div>
    )
}