/* eslint-disable */
import React, {useContext, useEffect} from "react";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import Urls from "../../../../Url/urls";
import man from '../../../../assets/img/profile/man.png';

import {CURRENCY} from "../../../../other/AppLevelConfig";
import {Card} from "@chakra-ui/react";

export default function UserProfile(props: { obj: any}) {
    const [wallet, setWallet] = React.useState<any>(null);
    const sessionData = useContext(AuthenticationContext);
    // const fetchWallet = () => {
    //     fetch(Urls.wallet, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Token " + sessionData.sessionData.token
    //         }
    //     }).then((response) => {
    //         return response.json();
    //     })
    //         .then((data) => {
    //             setWallet(data.wallet);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //
    //         })
    // }
    // useEffect(() => {
    //     fetchWallet();
    // }, []);
    const [loading, setLoading] = React.useState<boolean>(true);
    return (
        <Card className={"w-full fade-anim mb-8 mt-8 h-auto rounded-lg dark:text-white"}>
            {/*   create a div which acts as two colum on big screens and rows on sm */}
            <div className={"w-full grid grid-flow-row md:grid-flow-col  p-4"}>
                <div className={"justify-start col-span-1 bg-red  "}>
                    <img
                        className={"w-96 h-auto rounded-lg"}
                        src={
                            props.obj?.dp? Urls.mainDomain + props.obj?.DP.file : man
                        } />
                </div>
                <div className={"justify-start col-span-6 md:justify-center "}>
                    <div className={"text-black flex flex-row items-center text-2xl font-bold"}>{props.obj?.Name}
                        <div className={"text-sm flex flex-row shadow-lg shadow-black-50 text-white rounded-2xl bg-purple-600 font-semibold ml-5 p-2"}>
                            <div className={"text-sm font-normal mr-1"}>
                                Status:
                            </div>
                            {props.obj?.is_active ? " Active" : " Inactive"}
                        </div>
                    </div>
                    <div className={"text-black text-md font-normal"}>Date of Birth: {props.obj?.dob?
                    props.obj?.dob : "Not Available"
                    }</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.email}</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.phone}</div>
                    <div className={"text-black text-md font-normal"}>Date Joined: {props.obj?.date_joined}</div>


                </div>
            </div>
            <div className={"w-full grid grid-flow-row md:grid-flow-col  p-4"}>
                <div className={"justify-start col-span-1 md:justify-center mt-12 "}>
                    <div className={"text-black text-xl font-bold"}>Address</div>
                    <div className={"text-black text-md font-normal"}>{props.obj?.address?.address}</div>

                </div>

            </div>
            {/* create a horiziontal line with dashed border prominent */}
            {/*<div className={"w-full border-b-2 border-dashed border-black-900"}></div>*/}

            {/*<div className={"w-full p-4 text-black text-xl font-bold"}>Licenses*/}
            {/*    <p className={"text-black text-sm font-light"}>(Click to View)</p>*/}
            {/*</div>*/}



        </Card>
    )
}
