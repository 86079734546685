import ProfileBanner from "./components/ProfileBanner";
import PasswordChange from "./components/PasswordChange";

export default function Settings({...props}) {


    return (
        <div className={"flex w-full justify-center item-center pt-8 "}>
            <div className={"w-full flex flex-col  p-2  md:w-4/5 lg:w-4/5 3xl:w-1/2  gap-2 mt-4"}>
                <ProfileBanner  />
                <PasswordChange />
            </div>
        </div>

    )
}