import InBoxSelected from "./InBoxSelected";
import {VscEmptyWindow} from "react-icons/vsc";


function EmptyMessage() {

    return (
        <div className={"flex flex-col gap-2 items-center justify-center h-full"}>
            <VscEmptyWindow className={"text-gray-400 text-4xl dark:text-gray-300"}/>
            <p className={"text-gray-400 text-sm dark:text-gray-300"}>
                Select a message to view
            </p>
        </div>
    )
}

export default function MessagesScreen({className, selectedInbox, setSelectedInbox, ...props}) {


    return (
        <div
            className={`w-full bg-white dark:bg-navy-800 rounded-xl shadow-md justify-center overflow-auto items-center ${className}`}>

            {selectedInbox !== null ?
                <InBoxSelected selectedInbox={selectedInbox} setSelectedInbox={setSelectedInbox}/> :
                <EmptyMessage/>
            }
        </div>
    )
}