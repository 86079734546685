/* eslint-disable */
import React, {useEffect} from "react";
import tableDataColumns from "../../admin/tables/variables/tableDataColumns";
import {HiBookmark, HiOutlinePlus, HiUserAdd} from "react-icons/hi";
import {BsEyeFill} from "react-icons/bs";
import {getCookie} from "../common/functions";
import ServiceProviderTable from "../ServiceProviders/components/ServiceProviderTable";
import PromotionCards from "./components/PromotionCards";
import PromotionsTable from "./components/PromotionsTable";
import AddPromotion from "./components/AddPromotion";
import {RxCross2} from "react-icons/rx";


export default function Promotions() {
    const [addPromotion, setAddPromotion] = React.useState<boolean>(false);
    const [updateData, setUpdateData] = React.useState<object>(null);
    return (
        <div className={"w-full mt-8"}>
            <div className={"w-full flex justify-end mt-4 mb-4"}>
                <div
                    onClick={() => {
                        setUpdateData(null)
                        setAddPromotion(!addPromotion)
                    }}
                    className={"w-10 h-10 flex cursor-pointer items-center justify-center bg-white rounded-full shadow-2xl  dark:bg-navy-500"}>
                    {
                        !addPromotion ?
                            <HiOutlinePlus className={"w-6 text-navy-600 dark:text-gray-50 h-6"}/> :
                            <RxCross2 className={"w-6 text-navy-600 dark:text-gray-50 h-6"}/>
                    }

                </div>
            </div>

            {
                addPromotion ?
                    <AddPromotion updateData={updateData} viewTrigger={setAddPromotion}  userType={"Promotion"}/> :
                    <PromotionsTable title={"Promotions"}/>
            }


        </div>
    )
}