import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

export default function InBoxSelected({className, selectedInbox, setSelectedInbox, ...props}) {
    return (
        <div  className={`flex flex-col gap-2 w-full h-full overflow-auto  ${className}`}>
            <Header setSelected={setSelectedInbox} email={selectedInbox} />
            <Body selectedInbox={selectedInbox} setSelectedInbox={setSelectedInbox}/>
            <Footer selectedInbox={selectedInbox} setSelectedInbox={setSelectedInbox}/>
        </div>
    )
}