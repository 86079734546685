/* eslint-disable */
import React, {useContext, useEffect, useState} from "react";
import Card from "components/card";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import {FiSearch} from "react-icons/fi";
import {KDRUsers, ServiceProviders} from "../../../admin/tables/variables/tableDataColumns";
import Paginator from "../../../../components/table/Paginator";
import Urls from "../../../../Url/urls";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import man from '../../../../assets/img/profile/man.png';
import woman from '../../../../assets/img/profile/woman.png';
import {userTypes} from "../../../../other/AppLevelConfig";
import {calculateDifference, getBackgroundColor} from "../../common/functions";
import {HiInformationCircle, HiPencilAlt, HiTable, HiX} from "react-icons/hi";
import SwitchField from "../../../../components/fields/SwitchField";
import successNotification, {errorNotification} from "../../../../other/MakeNotication";


function ServiceProviderTable(props: { setSelected:any,tableData: any, title: string, viewTrigger?: any }) {


    const sessionData = useContext(AuthenticationContext);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const columns = [
        columnHelper.accessor("user.Name", {
            id: "name",
            header: () => (
                <p className="text-sm  font-bold text-gray-600 dark:text-white">NAME</p>
            ),
            cell: (info: any) => (
                <p onClick={()=>{
                    props.setSelected(info.row.original)
                }} className="flex flex-row gap-2 cursor-pointer items-center text-sm capitalize font-bold text-navy-700 dark:text-white">
                    <img alt={"dp"} className={"w-8 rounded-full h-8"}
                         src={info.row.original.dp !== null ? Urls.mainDomain + info.row.original.user.DP?.file :  man}/>
                    {info.getValue()}
                    


                </p>
            ),
        }),
        columnHelper.accessor("user.email", {
            id: "email",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    EMAIL
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),


        columnHelper.accessor("business_name", {
            id: "business_name",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">BUSINESS</p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("user.address.country", {
            id: "address",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">ADDRESS</p>
            ),
            cell: (info) => (
                <p onClick={()=>{
                    // on popup show card
                    //    get card by id
                    let card = document.getElementById("address-"+info.getValue());
                    if(card){
                        card.classList.toggle("hidden")
                    }



                }} className=" grid grid-cols-2 text-sm font-bold text-navy-700 dark:text-white cursor-pointer">
                    {info.row.original.user?.address?.city+", "+info.getValue()}
                    <HiInformationCircle className={"w-6 h-6"}/>
                    <Card id={"address-"+info.getValue()} className={"absolute p-4 bg-lightPrimary dark:bg-gray-900 shadow-inner shadow-2xl hidden rounded z-10"}>
                        {/*    grid 2 cols */}
                        <div className={"grid grid-cols-2 gap-4 "}>
                            <div>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>STREET</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>STATE</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>CITY</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>COUNTRY</p>

                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>ZIP CODE</p>
                            </div>
                            <div>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original.user?.address?.address}</p>

                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original.user?.address?.state}</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original.user?.address?.city}</p>
                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original.user?.address?.country}</p>

                                <p className={"text-sm font-bold text-gray-600 dark:text-white"}>{info.row.original.user?.address?.post_code}</p>
                            </div>

                        </div>
                    </Card>
                </p>
            ),
        }),
        columnHelper.accessor("license_count", {
            id: "license_count",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">LICENSES</p>
            ),
            cell: (info) => (
                <p onClick={()=>{
                    // on popup show card
                    //    get card by id
                    let card = document.getElementById("license-"+info.getValue());
                    if(card){
                    //    show license card if license count is greater than 0
                        if(info.getValue()>0){
                            card.classList.toggle("hidden")
                        }
                    }



                }} className=" grid grid-cols-2 text-sm font-bold text-navy-700 dark:text-white cursor-pointer">
                    {info.getValue()}
                    {info.getValue()>0 &&
                        <HiInformationCircle className={"w-6 h-6"}/>}
                    <Card id={"license-"+info.getValue()} className={"absolute w-50 h-500 p-4 bg-lightPrimary dark:bg-gray-900 shadow-inner shadow-2xl hidden rounded z-10"}>
                        {/*    grid 2 cols */}
                        {/*    close sign*/}
                        <div className={"flex justify-end"}>
                            <HiX className={"w-4 h-4 cursor-pointer"} />
                        </div>
                            <div className={"grid grid-cols-2 gap-4 "}>
                                <div>
                                    {
                                        info.row.original?.licenses?.map((license:any,index)=>{
                                            return (<p className={"mt-4"}>
                                                    <a className={"text-blue-500"} target="_blank" href={license?.file}>{
                                                        license?.file.split("/")[license?.file.split("/")?.length-1]
                                                    }</a>
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                    </Card>
                </p>
            ),
        }),

        columnHelper.accessor("user.date_joined", {
            id: "date_joined",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">DATE JOINED</p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("is_verified", {
            id: "edit",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">STATUS</p>
            ),
            cell: (info) => (
                <div className={"flex flex-row gap-2"}>
                    <SwitchField  id={"SPStatus"} label={""} desc={""}
                                  checked={info.row.original.is_verified}
                                  setChecked={(value: any) => {
                                        changeSPStatus(info.row.original.id, value ? "approve" : "block")



                                  }}

                                  placeholder={"SP Status"} mt={""} mb={""}
                    />
                </div>
            ),
        }),

    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => []);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    const [query, setQuery] = React.useState({
        page: 1,
        page_size: 4,
        role: props.title,
        query: "",
    });
    const [paginatorQuery, setPaginatorQuery] = useState({
        page: 1,
        next: false,
        previous: false,
    })
    function changeSPStatus(id: any, action: any) {
        let data = {
            action: action,
            serviceProvider: id

        }
        fetch(Urls.changeServiceProviderStatus, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "token " + sessionData.sessionData.token,
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        }).then((json) => {
            if(json?.success){
                successNotification("Success", json.success)
            }
            else{
                errorNotification("Error", json.error)

            }
        }).finally(() => {
            fetchData()
        })
        .catch((error) => {
            console.log(error)
            errorNotification("Error", "Something went wrong")
        })
        }
    function fetchData() {
        let data = `page=${query.page}&page_size=${query.page_size}&name=${query.query}`;
        fetch(Urls.allserviceProviders + "?" + data, {
            method: "GET",
            headers: {
                Authorization: "token " + sessionData.sessionData.token,
            }
        }).then((response) => {
            return response.json();
        }).then((json) => {
            if (json) {
                setData(json.results);
                for (let i in json.results){
                    // if (json.results[i].user.name !== null){
                    //     json.results[i].user.dpUrl = Urls.mainDomain + json.results[i].user.dpUrl
                    // }
                    console.log(json.results[i])
                }
                setPaginatorQuery({
                    page: query.page, next: !!json.next, previous: !!json.previous

                })
                console.log(json)

            }

        })

    }


    useEffect(() => {
        fetchData();
    }, [query, props.title])


    return (
        <Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl capitalize font-bold text-navy-700 dark:text-white">
                    {props.title.toLowerCase()} data
                </div>
                <div
                    className="flex h-full p-2 items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white"/>
                    </p>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => {
                            setQuery({...query, query: e.target.value, page: 1});
                        }}
                        className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                </div>

            </header>

            <div className="mt-8 overflow-x-auto">
                <table className="w-full">
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="!border-px !border-gray-400">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                                    >
                                        <div className="items-center justify-between text-xs text-gray-200">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: "",
                                                desc: "",
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table
                        .getRowModel()
                        .rows
                        .map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                key={cell.id}
                                                className="min-w-[150px] border-white/0 py-3  pr-4"
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Paginator
                page={paginatorQuery.page}
                next={paginatorQuery.next}
                previous={paginatorQuery.previous}
                onPageChange={(page) => {
                    setQuery({
                        ...query,
                        page: page,
                    })
                }
                }
            />
        </Card>
    );
}

export default ServiceProviderTable;
const columnHelper = createColumnHelper<ServiceProviders>();
