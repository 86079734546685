/* eslint-disable */
import InputField from "components/fields/InputField";
import {FcGoogle} from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "axios";
import Urls from "../../Url/urls";
import React, {useContext, useState} from "react";
import {AuthenticationContext} from "../../context/authenticationContext";
import successNotification, {errorNotification} from "../../other/MakeNotication";
import {Link} from "react-router-dom";

export default function SignIn() {
    const [error, setError] = useState<string>("");
    const sessionContext = useContext(AuthenticationContext);

    function postData(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setError("");
        var formdata = new FormData(event.target as HTMLFormElement);
        fetch(Urls.login, {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        })
            .then(response => {
                let json = response.json();
                if (response.status === 200) {
                    return json;
                } else if (response.status === 401) {
                    json.then(err => {
                        setError(err.error);
                    });
                } else {

                }
            })
            .then(result => {
                if (result === undefined) {
                    return;
                }
                localStorage.setItem("sessionData", JSON.stringify(result));
                sessionContext.setSessionData(result);
                successNotification("Login Successful", "Welcome back!");
                window.location.href = "/admin/default";
            })
            .catch(error => {

                errorNotification("Something went wrong", error.message);
                console.log('error', error)
            });
    }

    return (
        <div
            className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <form onSubmit={postData}
                  className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Sign In
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your email and password to sign in!
                </p>

                {/* Email */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    state="none"
                    error={""}
                    name="email"
                    placeholder="mail@email.com"
                    id="email"
                    type="text"
                />

                {/* Password */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    name="password"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="password"
                    type="password"
                />
                <p className="text-xs text-red-500 mt-1 mb-2 ml-2 dark:text-red-400">{error}</p>
                {/* Checkbox */}
                <div className="mb-4 flex items-center justify-between px-2">

                    <Link
                        className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"

                        to={"/auth/forget-password"}
                    >
                        Forgot Password?
                    </Link>
                </div>
                <button
                    type={"submit"}
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Sign In
                </button>
            </form>
        </div>


    );
}
