import {createContext, useEffect, useState} from "react";

const DefaultData = {
    token: null,
    profile: {
        id: 3,
        email: null,
        roles: null,
        Name: null,
        dpUrl: null,
    }
};

const AuthenticationContext = createContext({
    sessionData: DefaultData,
    setSessionData: (data) => {
    },
    removeSessionData: () => {
    },
    sessionDataSingleKeySetter: () => {
    },
});

export default function AuthenticationContextProvider({children}) {
    const [sessionData, setSessionData] = useState(DefaultData);
    const removeSessionData = () => {
        localStorage.removeItem("sessionData");
        setSessionData(DefaultData);
    };

    const sessionDataSingleKeySetter = (key, value) => {
        setSessionData(() => {
            return {...sessionData, [key]: value};
        });
    };

    function setSessionData2(data) {
        var dataCopy = Object.assign({}, data);
        setSessionData(dataCopy);
    }

    const Value = {
        sessionData: sessionData,
        setSessionData: setSessionData2,
        removeSessionData: removeSessionData,
        sessionDataSingleKeySetter: sessionDataSingleKeySetter,
    }

    useEffect(() => {
        let data = localStorage.getItem("sessionData");
        if (data ) {
            setSessionData(JSON.parse(data));
        }
    }, [])

    return (
        <AuthenticationContext.Provider
            value={Value}
        >
            {children}
        </AuthenticationContext.Provider>
    );
}


export {
    AuthenticationContext,
};