/* eslint-disable */
import React from "react";


export default function Paginator(
    props: {
        page: number,
        onPageChange: (page: number) => void,
        next: boolean,
        previous: boolean,

    }
) {
    return (
        <div className={"flex justify-end  items-center"}>

            <div
                onClick={() => {
                    if (props.previous) {
                        props.onPageChange(props.page - 1)
                    }
                }}
                className={`flex ${!props.previous ? "hidden" : null} cursor-pointer items-center justify-center bg-white shadow-2xl dark:bg-navy-800`}>

                <p className={"border-white text-3xl  dark:text-gray-50"}>
                    {"<"}
                </p>
            </div>
            <p className={"w-6 border-white text-md text-center  dark:text-gray-50 h-6"}>
                {props.page}
            </p>
            <div
                onClick={() => {
                    if (props.next) {
                        props.onPageChange(props.page + 1)
                    }
                }}
                className={`"flex ${!props.next ? "hidden" : null}  cursor-pointer items-center justify-center  shadow-2xl  dark:bg-navy-800"`}>

                <p className={"border-white text-3xl  dark:text-gray-50"}>
                    {">"}
                </p>
            </div>
        </div>
    )
}