/* eslint-disable */
import React, {useContext, useEffect} from "react";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import Urls from "../../../../Url/urls";
import {CURRENCY} from "../../../../other/AppLevelConfig";
import man from "../../../../assets/img/profile/man.png";
import InputField from "../../../../components/fields/InputField";
import {Button} from "@chakra-ui/react";
import successNotification, {errorNotification} from "../../../../other/MakeNotication";

export default function WalletCard() {
    const [wallet, setWallet] = React.useState<any>({
        amount: 0,
        stripe:0,
        withdrawn:0
    });
    const sessionData = useContext(AuthenticationContext);
    const fetchWallet = () => {
        fetch(Urls.wallet, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + sessionData.sessionData.token
            }
        }).then((response) => {
            return response.json();
        })
            .then((data) => {
                setWallet(data.wallet);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);

            })
    }
    useEffect(() => {
        fetchWallet();
    }, []);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [withdraw, setWithdraw] = React.useState<any>(0);
    const [withdrawError, setWithdrawError] = React.useState<any>("");
    return (
        <div className={"fade-anim grid grid-flow-row md:grid-flow-col rounded-lg bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 p-4"}>
            <div className={"flex justify-center items-center flex-col"}>
                <div className={"text-white text-lg font-bold"}>Stripe Wallet</div>
                <div className={"text-white text-[80px]"}>{
                    `${wallet?.stripe}${CURRENCY}`
                } </div>
                <div className={"flex flex-row "}>
                    <div className={"text-white justify-center items-center flex flex-row text-[25px]"}>
                        <div className={"text-sm mr-1"}>Balance</div>
                        {
                            `${wallet?.amount}${CURRENCY}`
                        }
                    </div>
                    <div className={"text-white ml-8 justify-center items-center flex flex-row text-[25px]"}>
                        <div className={"text-sm mr-1"}>Withdrawn</div>
                        {
                            `${wallet?.withdrawn}${CURRENCY}`
                        }
                    </div>



                </div>
            </div>
            <div className={" p-4 md:visible md:w-full  rounded-2xl  bg-white shadow-lg shadow-black-900 flex-col dark:!bg-navy-800 dark:text-white"}>
                {/*        image of wallet*/}
                <div className={"text-black-900 dark:text-white text-lg font-bold"}>Withdraw Amount</div>
                <InputField id={"withdraw"} label={""} extra={""}
                            name={"amount"} placeholder={"Enter Withdrawal amount"}
                            error={withdrawError}
                            variant={""} type={"number"} value={""} onChange={(e)=>{
                    if(e.target.value > wallet?.amount){
                        setWithdraw(e.target.value)
                        setWithdrawError("Amount exceeds wallet balance")
                    }else if(parseInt(e.target.value) < 0){
                        setWithdraw(e.target.value)
                        setWithdrawError("Amount cannot be negative")
                    }else{
                        setWithdraw(e.target.value)
                        setWithdrawError("")
                    }


                }}/>
                <Button onClick={()=>{
                    if(withdrawError.length > 0){
                        return
                    }
                    if(parseInt(withdraw) >parseInt(wallet.amount)){
                        setWithdrawError("Amount exceeds wallet balance")
                        return
                    }
                    if(parseInt(withdraw) < 0){
                        setWithdrawError("Amount cannot be negative")
                        return
                    }
                    let Data = {
                        amount: withdraw
                    }
                    fetch(
                        Urls.withdrawAmount,{
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Token " + sessionData.sessionData.token
                            },
                            body: JSON.stringify(Data)

                        }).then(
                        (response) => {
                            return response.json();
                        }).then((DATA) => {
                        if(DATA.status===200){
                            successNotification("Withdrawal Successful","Amount will be credited to your account in 3-5 business days")
                        }else{
                            errorNotification("Withdrawal Failed",DATA.msg)

                        }
                        setWithdrawError("")
                    }).catch((error) => {
                        setWithdrawError("")
                        errorNotification("Withdrawal Failed","Something went wrong")
                    })




                }}
                        className={"w-full mt-4 bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 text-white rounded-lg p-2"}>
                    Withdraw
                </Button>
            </div>
        </div>
    )
}