

import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import {useContext} from "react";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import {getUrl} from "../../../../other/extra";
import Urls from "../../../../Url/urls";

const ProfileBanner = () => {

    const session= useContext(AuthenticationContext);

    return (
        <Card extra={"items-center w-full  p-[16px]  bg-cover"}>
            {/* Background and profile */}
            <div
                className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
                style={{ backgroundImage: `url(${banner})` }}
            >
                <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                    <img className="h-full w-full rounded-full" src={Urls.mainDomain  + session.sessionData.profile.dpUrl} alt="" />
                </div>
            </div>

            {/* Name and position */}
            <div className="mt-16 flex flex-col items-center">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    {session.sessionData.profile.Name}
                </h4>
                <h4 className="text-xs text-gray-400    dark:text-white">
                    {session.sessionData.profile.email}
                </h4>
            </div>

            {/* Post followers */}
        </Card>
    );
};

export default ProfileBanner;
