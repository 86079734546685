import {Routes, Route, Navigate} from "react-router-dom";

// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import AuthenticationContextProvider, {AuthenticationContext} from "./context/authenticationContext";
import {useContext} from "react";



const Main = () => {
    const sessionData = useContext(AuthenticationContext);
    if (sessionData.sessionData.token === null) {
        return (
            <Routes>
                <Route path="auth/*" element={<AuthLayout/>}/>
                <Route path="*" element={<Navigate to="/auth/sign-in" replace/>}/>
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="admin/*" element={<AdminLayout/>}/>
            <Route path="*" element={<Navigate to="/admin" replace/>}/>

        </Routes>
    );

}


const App = () => {
    return (
        <AuthenticationContextProvider>
            <Main/>

        </AuthenticationContextProvider>
    );
};

export default App;
