const AppName: string = "Lunatech";


export const userTypes = {
    CLIENTS: "CLIENTS",
    SERVICE_PROVIDERS: "SERVICE PROVIDERS",
    STAFF: "STAFF"
}
export const CURRENCY = "£"
export const GOOGLE_MAPS_API_KEY: string = "AIzaSyDPvCZmeGuN-y_pJ3ug7PYrwp6BHyKnNUY";

export default AppName;
