import InputField from "../../components/fields/InputField";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spinner} from "@chakra-ui/react";
import Urls from "../../Url/urls";
import {successNotify} from "../../other/extra";


export default function ForgetPassword() {
    const [error, setError] = useState({});
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        setError({})

        fetch(Urls.ForgePassword, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', "Accept": "application/json",
            }, body: JSON.stringify(form)
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    email: "Email not found"
                })
                return null;
            }
            return res.json()


        }).then((data) => {
            if (data === null) return;
            successNotify("Otp sent ","Please check your email")
            navigate("/auth/otp", {
                state: {
                    email: form.email,
                }
            })

        }).finally(() => {
            setLoading(false)
        })


    }

    return (<div
            className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <form
                onSubmit={handleSubmit}

                className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[480px]">
                <h4 className="mb-2.5 text-2xl md:text-3xl lg:text-4xl font-bold text-navy-700 dark:text-white">
                    Forgot your password?
                </h4>
                <p className="mb-9 ml-1 text-xs  md:text-base text-gray-400">
                    No problem. Just let us know your email address and we'll email you a Otp to reset your password.
                </p>
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    error={error?.email}
                    value={form.email}
                    onChange={(e) => {
                        setForm({
                            ...form, email: e.target.value
                        })
                    }}
                    required
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    name={"Email"}
                />
                <button
                    type={"submit"}
                    disabled={loading}
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    {loading ? <Spinner className={"w-4 h-4"}/> : "Send Otp"}
                </button>
            </form>
        </div>

    )
}