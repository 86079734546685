import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import InputField from "../../components/fields/InputField";
import {Spinner} from "@chakra-ui/react";
import Urls from "../../Url/urls";
import {successNotify} from "../../other/extra";

export default function NewPassword({...props}) {
    const {state} = useLocation();
    const [form, setForm] = useState({
        password: "",
        confirmPassword: "",
        ...state
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        if (form.password !== form.confirmPassword) {
            setError({
                confirmPassword: ["Passwords do not match."]
            })
            return;
        }
        setLoading(true);
        fetch(Urls.SetNewPassword, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', "Accept": "application/json",
            }, body: JSON.stringify({
                email: state.email,
                otp: state.otp,
                password: form.password
            })
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    password: "Otp expired",
                    confirmPassword: "Otp expired",
                })
                return null;
            }
            return res.json()


        }).then((data) => {
            if (data === null) return;
            successNotify("Password changed", "You can now login with your new password")
            navigate("/auth/sign-in")

        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div
            className="mt-16 mb-16 flex w-full h-full  items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <form
                onSubmit={handleSubmit}
                className="mt-[10vh] w-full max-w-full gap-0 flex-col items-start  md:pl-4 lg:pl-0 xl:max-w-[480px]">
                <h4 className="mb-2.5 text-3xl md:text-3xl lg:text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                </h4>
                <p className="mb-9 ml-1 text-sm  md:text-base text-gray-400">
                    Enter your new password. Make sure it's at least 8 characters including a number and a lowercase
                </p>
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    name="Password"
                    error={error?.password}
                    value={form.password}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            password: e.target.value
                        })
                    }}
                    required
                    placeholder="**********"
                    pattern="(?=.*\d)(?=.*[a-z]).{8,}"

                    title="Make sure it's at least 8 characters including a number and a lowercase and symbol"
                    id="password"
                    type="password"
                />
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Confirm Password*"
                    error={error?.confirmPassword}
                    value={form.confirmPassword}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            confirmPassword: e.target.value
                        })
                    }}
                    required
                    placeholder="**********"
                    pattern="(?=.*\d)(?=.*[a-z]).{8,}"
                    id="ConfirmPassword"
                    type="password"
                    name={"Confirm Password"}
                />

                <button
                    type={"submit"}
                    disabled={loading}
                    className="linear mt-8 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    {
                        loading ? <Spinner className={"w-4 h-4"}/> : "Change Password"
                    }
                </button>

            </form>
        </div>
    )
}