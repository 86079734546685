/* eslint-disable */
import avatar from "assets/img/profile/profile.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import InputField from "../../../../components/fields/InputField";
import React, {useContext, useEffect} from "react";
import DropDownField from "../../../../components/fields/DropDownField";
import "../../../../assets/css/fade.css"
import Urls from "../../../../Url/urls";
import {AuthenticationContext} from "../../../../context/authenticationContext";
import successNotification, {errorNotification} from "../../../../other/MakeNotication";
import UploadFile from "../../../../other/uploadFile";

import {getCookie} from "../../common/functions";

const Types = [
    {
        value: "BOTH",
        label: "Both"
    }, {
        value: "CLIENT",
        label: "Client"
    }, {
        value: "SERVICE_PROVIDER",
        label: "Service Provider"
    },
]
const DefaultError = {
    "title": [
        ""
    ],
    "promotion_type": [
        ""
    ],
    "description": [
        ""
    ],
    "image": [
        ""
    ],


}

const randomPassword = () => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
const AddPromotion = (props: {
    userType: string,
    viewTrigger: any,
    updateData?: any
}) => {


    const sessionData = useContext(AuthenticationContext);
    const [error, setError] = React.useState<any>(DefaultError)
    const [zone, setZone] = React.useState<any>([])
    const [selectedZone, setSelectedZone] = React.useState<any>(props.updateData !== null ? props.updateData?.user?.zone : null)
    const [data, setData] = React.useState<any>({
        title:"",
        promotion_type:"BOTH",
        description:"",
        image:null,


    })

    function PostForm(data: any) {
        console.log(data.get("image"))
        let formData = new FormData();
        formData.append("title", data.get("title") as string);
        formData.append("promotion_type", data.get("promotion_type") as string);
        formData.append("description", data.get("description") as string);
        formData.append("image", data.get("image") as File);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "token " + sessionData.sessionData.token);
        // use content type for form data

        let method = "POST"
        let Url = Urls.PromotionCreateUpdate
        fetch(Url, {
            mode: 'no-cors',
            method: method,
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)

                props.viewTrigger(false)
                // if (response.status === 400) {
                //     response.json().then((data) => {
                //         console.log(data)
                //         setError(updateError(DefaultError, data))
                //         errorNotification("Error", "Please Check the form again");
                //     })
                // } else if (response.status === 201) {
                //     successNotification("Success", "Promotion Added Successfully")
                //     props.viewTrigger(false)
                // }
            })
            .catch((error) => {
                console.log(error)
                errorNotification("Error", "Something went wrong")
                })
    }
    const [autocomplete, setAutocomplete] = React.useState<any>(null);
    function getZoneCoordinates(ZoneId: any) {
        let zone2: any = zone.find((zone: any) => zone.value == ZoneId)
        if (zone2) {
            return zone2
        }
        return null
    }
    const updateError = (originalError: any, newError: any): any => {
        // Create a new error object by merging the original error and the new error
        let updatedError: any = {
            ...originalError,
            ...newError,
        };

        return updatedError;
    };

    const dpRef = React.useRef<HTMLInputElement>(null);

    function PostData(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setError(DefaultError)
        const data = new FormData(event.currentTarget);
        let file = data.get("image") as File;
        if (file.size === 0) {
            let imageError = "Please upload a valid image"
            setError(updateError(error, {image: [imageError]}))
            errorNotification("Error", "Please add a valid image")

            return
        }
        else{
            PostForm(data )
        }


    }

    return (
        <div className={"flex fade-anim w-full justify-center"}>
            <Card extra={"items-center w-full lg:w-2/3 h-full p-[16px] bg-cover"}>
                {/* Background and profile */}
                <div
                    className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
                    style={{backgroundImage: `url(${banner})`}}
                >

                    <label htmlFor={"dp"}
                           className="absolute  -bottom-12 flex h-[107px] w-[107px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                        <img id={"avatar"} className="h-full w-full rounded-full"
                             src={avatar } alt=""/>
                    </label>
                </div>

                {/* Sign in section */}
                <form id={"patient-form"} onSubmit={PostData}
                      className="mt-[10vh] w-full flex-col items-center md:pl-4 lg:pl-0 ">
                    <input
                        name={"image"}
                        type={"file"}
                        onChange={(e) => {
                            let file = e.target.files![0];
                            let reader = new FileReader();
                            let image = document.getElementById("avatar") as HTMLImageElement;
                            reader.onloadend = () => {
                                image.src = reader.result as string;
                            }
                            reader.readAsDataURL(file);
                            setData({...data, image: file})
                        }}
                        className={"hidden"}
                        id={"dp"}
                    />
                    <h4 className="mb-2.5 text-4xl capitalize font-bold text-navy-700 dark:text-white">
                        {
                            props.updateData ? "Update " : "Add "
                        } {props.userType.toLowerCase()}
                    </h4>
                    <div className={"grid lg:grid-cols-2  gap-4"}>
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            value={data.title}

                            label="Title*"
                            state="none"

                            error={error.title[0]}
                            required={true}
                            name="title"
                            placeholder="Enjoy 50% off on all services"
                            id="Title"
                            type="text"
                        />
                        <DropDownField
                            variant="auth"
                            extra="mb-3"
                            label="Promotion Type*"
                            value={data.promotion_type}
                            state="none"
                            error={error.promotion_type[0]}
                            name="promotion_type"
                            placeholder="Select Promotion Type"
                            id="PromotionType"
                            fields={Types}
                        />

                        {/* Email */}

                    </div>

                    <div className={"grid lg:grid-cols-1  gap-4"}>

                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Description*"
                            value={data.description}

                            state="none"
                            required={true}
                            error={error.description[0]}
                            name="description"
                            placeholder="Short Description"
                            id="Description"
                            type="text"
                        />

                    </div>




                    <button
                        type={"submit"}
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Save
                    </button>
                </form>


            </Card>
        </div>
    );
};

export default AddPromotion;
