/* eslint-disable */
import React from "react";


// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
    MdHome,

    MdPerson,
    MdLock,
} from "react-icons/md";
import {userTypes} from "./other/AppLevelConfig";
import {
    GiFoldedPaper,
    GiPerson, GiPolarStar, GiToolbox, GiWallet,

} from "react-icons/gi";
import {FiSettings} from "react-icons/fi";

import KDRDashboard from "./views/kdr/dashboard";
import Users from "./views/kdr/Users/Users";
import ServiceProvider from "./views/kdr/ServiceProviders/ServiceProvider";
import Memberships from "./views/kdr/Memberships/Memberships";
import Services from "./views/kdr/Services/Services";
import ForgetPassword from "./views/auth/ForgetPassword";
import OTP from "./views/auth/OTP";
import NewPassword from "./views/auth/NewPassword";
import HelpAndSupport from "./views/admin/HelpAndSupport/HelpAndSupport";
import Settings from "./views/admin/Settings/Settings";
import {BsFillTicketPerforatedFill} from "react-icons/bs";
import Wallet from "./views/kdr/Wallet/Wallet";
import Promotions from "./views/kdr/Promotions/Promotions";


const DefaultRoutes = [
    {
        name: "Sign In",
        layout: "/auth",
        path: "sign-in",
        icon: <MdLock className="h-6 w-6"/>,
        component: <SignIn/>,
    },

];

const AdminRoutes = [
    {
        name: "Sign In",
        layout: "/auth",
        path: "sign-in",
        icon: <MdLock className="h-6 w-6"/>,
        component: <SignIn/>,
    }, {
        name: "Forget Password",
        layout: "/auth",
        path: "forget-password",
        icon: <MdLock className="h-6 w-6"/>,
        component: <ForgetPassword/>,
    }, {
        name: "Otp",
        layout: "/auth",
        path: "otp",
        icon: <MdLock className="h-6 w-6"/>,
        component: <OTP/>,
    }, {
        name: "New Password",
        layout: "/auth",
        path: "reset-password",
        icon: <MdLock className="h-6 w-6"/>,
        component: <NewPassword/>,
    },
    {
        name: "Dashboard",
        layout: "/admin",
        path: "dashboard",
        icon: <MdHome className="h-6 w-6"/>,
        component: <KDRDashboard/>,
    },
    // {
    //     name: "Route",
    //     layout: "/admin",
    //     path: "RoutePage",
    //     icon: <MdRoute className="h-6 w-6"/>,
    //     component: <RouteMain />,
    // },
    // {
    //     name: "Zone",
    //     layout: "/admin",
    //     path: "Zone",
    //     icon: <MdMap className="h-6 w-6"/>,
    //     component: <Zone/>,
    // },
    {
        name: "Clients",
        layout: "/admin",
        path: "Clients",
        icon: <GiPerson className="h-6 w-6"/>,
        component: <Users userType={userTypes.CLIENTS}/>,
    }, {
        name: "Service Providers",
        layout: "/admin",
        path: "ServiceProviders",
        icon: <MdPerson className="h-6 w-6"/>,
        component: <ServiceProvider userType={userTypes.SERVICE_PROVIDERS}/>,

    },

    {
        name: "Wallet",
        layout: "/admin",
        path: "Wallets",
        icon: <GiWallet className="h-6 w-6"/>,
        component: <Wallet />,

    },
    {
        name: "Promotions",
        layout: "/admin",
        path: "Promotions",
        icon: <GiPolarStar className="h-6 w-6"/>,
        component: <Promotions />,

    },
    {
        name: "Memberships",
        layout: "/admin",
        path: "Memberships",
        icon: <GiFoldedPaper className="h-6 w-6"/>,
        component: <Memberships />,

    },

    {
        name: "Services",
        layout: "/admin",
        path: "Services",
        icon: <GiToolbox className="h-6 w-6"/>,
        component: <Services/>,

    },

    {
        name: "Help and Support",
        layout: "/admin",
        path: "help-and-support",
        icon: <BsFillTicketPerforatedFill className="h-6 w-6"/>,
        component: <HelpAndSupport/>,

    }, {
        name: "Settings",
        layout: "/admin",
        path: "settings",
        icon: <FiSettings className="h-6 w-6"/>,
        component: <Settings />

    }
    // ,{
    //     name: "Staffs",
    //     layout: "/admin",
    //     path: "Staff",
    //     icon: <MdPerson4 className="h-6 w-6"/>,
    //     component: <Staff userType={userTypes.STAFF}/>,
    // }
];
export { AdminRoutes,DefaultRoutes,}
export default AdminRoutes;
