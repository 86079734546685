// Custom components
import React from "react";

function DropDownField(props: {
    id: string;
    label: string;
    extra: string;
    name: string;
    placeholder: string;
    variant: string;
    state?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    error?: string;
    disabled?: boolean;
    defaultValue?: any;
    fields?: Array<{ value: string; label: string }>;
}) {
    const {
        label,
        id,
        extra,
        fields,
        name,
        placeholder,
        variant,
        state,
        value,
        error,
        disabled,
        onChange,
        defaultValue
    } =
        props;
    let state2 = error !== undefined && error.length > 0 ? "error" : state;

    return (
        <div className={`${extra}`}>
            <label
                htmlFor={id}
                className={`text-sm text-navy-700 dark:text-white ${
                    variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
                }`}
            >
                {label}
            </label>
            <select
                disabled={disabled}
                name={name ? name : ""}
                id={id}
                defaultValue={value}
                onChange={onChange ? onChange : () => {
                }}
                placeholder={placeholder}
                className={`mt-2 flex h-12 w-full items-center pr-3 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
                    disabled === true
                        ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                        : state2 === "error"
                            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                            : state2 === "success"
                                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                : "border-gray-200 dark:!border-white/10 dark:text-white"
                }`}
            >
                <option className={"dark:bg-gray-900 dark:text-white"} selected={true} disabled>
                    {placeholder}

                </option>
                {fields?.map((field, index) => (
                    <option selected={defaultValue === field.value} className={"dark:bg-gray-900 dark:text-white "}
                            key={index} value={field.value}>
                        {field.label}
                    </option>
                ))}

            </select>{
            error !== undefined ?
                <p className={`${error.length > 0 ? "mt-1" : ""} text-xs text-red-500  ml-2 dark:text-red-400`}>{error}</p> : null

        }
        </div>
    );
}

export default DropDownField;
