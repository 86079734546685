/* eslint-disable */
export default function ReceiverMessage({className,text,time,dp, ...props}) {

    return (
        <div className={`w-full flex  flex-row gap-2 ${className}`}>
            <img src={dp.uri} className={"w-8 h-8 rounded-full"} />
            <div className={"max-w-4/5 lg:max-w-[50%]  bg-gray-200 dark:bg-navy-900 flex flex-col rounded-b-3xl rounded-tr-3xl p-3"}>
               <p className={"text-gray-800 dark:text-gray-200 break-words text-sm"}>
                   {text}
               </p>

                <p className={"text-xs mt-3 dark:text-gray-300 self-end text-gray-900"}>
                    {time}

                </p>
            </div>
        </div>
    )
}