import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import OtpInput from "../../components/fields/OtpInput";
import {Spinner} from "@chakra-ui/react";
import Urls from "../../Url/urls";
import {successNotify} from "../../other/extra";


export default function OTP({...props}) {
    const {state} = useLocation();
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        fetch(Urls.ValidateOtp, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', "Accept": "application/json",
            }, body: JSON.stringify({
                email: state.email,
                otp: otp
            })
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    otp: "Invalid OTP"
                })
                return null;
            }
            return res.json()


        }).then((data) => {
            if (data === null) return;
            successNotify("Otp verified ", "Please enter your new password")
            navigate("/auth/reset-password", {
                state: {
                    email: state.email,
                    otp: otp
                }
            })

        }).finally(() => {
            setLoading(false)
        })


    }

    return (
        <div
            className="mt-16 mb-16 flex w-full h-full  items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <div
                className="mt-[10vh] w-full max-w-full gap-0 flex-col items-center flex md:pl-4 lg:pl-0 xl:max-w-[480px]">
                <h4 className="mb-2.5 text-3xl md:text-3xl lg:text-4xl font-bold text-navy-700 dark:text-white">
                    Otp Verification
                </h4>
                <p className="mb-9 ml-1 text-sm  md:text-base text-gray-400">
                    Enter the OTP sent to your email address.
                </p>

                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    error={error?.otp}
                    numInputs={4}
                    shouldAutoFocus={true}
                    placeholder={'O'}

                />
                <button
                    type={"button"}
                    onClick={handleSubmit}
                    disabled={loading}
                    className="linear mt-8 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    {
                        loading ? <Spinner className={"w-4 h-4"}/> : "Verify"
                    }
                </button>

            </div>
        </div>
    )
}