/* eslint-disable */
import React, {useContext, useEffect} from "react";
import tableDataColumns from "../../admin/tables/variables/tableDataColumns";
import {HiBookmark, HiUserAdd} from "react-icons/hi";
import {BsEyeFill} from "react-icons/bs";
import {getCookie} from "../common/functions";
import ServiceProviderTable from "../ServiceProviders/components/ServiceProviderTable";
import WalletCard from "./components/WalletCard";
import PaymentsTable from "./components/PaymentsTable";
import WalletCardOld from "./components/WalletCardOld";
import {AuthenticationContext} from "../../../context/authenticationContext";


export default function Wallet() {
    const sessionData = useContext(AuthenticationContext);
    const [clicked, setClicked] = React.useState<boolean>(false);
    return (
        <div onClick={()=>{
            setClicked(true);
        }} className={"w-full mt-8"}>

            {
                clicked?
                    <WalletCard />:
                    <WalletCardOld />
            }

            <PaymentsTable title={"Payments"} />

        </div>
    )
}