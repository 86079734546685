/* eslint-disable */

import React, {useContext} from "react";
import Dropdown from "components/dropdown";
import {FiAlignJustify} from "react-icons/fi";
import {Link} from "react-router-dom";
import {FiSearch} from "react-icons/fi";
import {RiMoonFill, RiSunFill} from "react-icons/ri";
import {AuthenticationContext} from "../../context/authenticationContext";
import Urls from "../../Url/urls";

const Navbar = (props: {
    onOpenSidenav: () => void;
    brandText: string;
    secondary?: boolean | string;
}) => {
    const {onOpenSidenav, brandText} = props;
    const [darkmode, setDarkmode] = React.useState(false);
    const sessionData = useContext(AuthenticationContext);
    return (
        <nav
            className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
            <div className="ml-[6px]">
                <div className="h-6 w-[224px] pt-1">
                    <a
                        className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                        href=" "
                    >
                        AdminPortal
                        <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
                            /{" "}
            </span>
                    </a>
                    <Link
                        className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                        to="#"
                    >
                        {brandText}
                    </Link>
                </div>
                <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                    <Link
                        to="#"
                        className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
                    >
                        {brandText}
                    </Link>
                </p>
            </div>

            <div
                className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">

                <span
                    className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
                    onClick={onOpenSidenav}
                >
          <FiAlignJustify className="h-5 w-5"/>
        </span>
                {/* start Notification */}

                <div
                    className="cursor-pointer text-gray-600"
                    onClick={() => {
                        if (darkmode) {
                            document.body.classList.remove("dark");
                            setDarkmode(false);
                        } else {
                            document.body.classList.add("dark");
                            setDarkmode(true);
                        }
                    }}
                >
                    {darkmode ? (
                        <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white"/>
                    ) : (
                        <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white"/>
                    )}
                </div>
                {/* Profile & Dropdown */}
                <Dropdown
                    button={
                        <img
                            className="h-10 w-10 rounded-full"
                            src={Urls.mainDomain  + sessionData.sessionData.profile.dpUrl}
                            alt=""
                        />
                    }
                    children={
                        <div
                            className="flex max-h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                            <div className="mt-3 ml-4">
                                <div className="flex items-center gap-2">
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        👋 Hi, {sessionData?.sessionData.profile.Name.length > 15 ?
                                        sessionData?.sessionData.profile.Name.substring(0, 15) + "..." :
                                        sessionData?.sessionData.profile.Name
                                    }
                                    </p>{" "}
                                </div>
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 "/>

                            <div className="mt-3 ml-4 flex flex-col">
                                {/*<a*/}
                                {/*    href=" "*/}
                                {/*    className="text-sm text-gray-800 dark:text-white hover:dark:text-white"*/}
                                {/*>*/}
                                {/*    Profile Settings*/}
                                {/*</a>*/}
                                {/*<a*/}
                                {/*    href=" "*/}
                                {/*    className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"*/}
                                {/*>*/}
                                {/*    Newsletter Settings*/}
                                {/*</a>*/}
                                <div
                                    onClick={() => sessionData.removeSessionData()}
                                    className="mt-3 mb-2 text-sm font-medium cursor-pointer text-red-500 hover:text-red-500"
                                >
                                    Log Out
                                </div>
                            </div>
                        </div>
                    }
                    classNames={"py-2 top-8 -left-[180px] w-max"}
                />
            </div>


        </nav>
    );
};

export default Navbar;
