/* eslint-disable */


const getMarkerColor = (lastSeen:any) => {
    let differenceInDays = parseInt(lastSeen)
    if (differenceInDays < 0){
        return "blue"
    }
    else if (differenceInDays <= 7 && differenceInDays >= 0){
        return "green"
    }
    else if (differenceInDays <= 30 && differenceInDays > 7){
        return "orange"
    }
    else if (differenceInDays > 30){
        return "red"
    }
};
const calculateDifference = (lastSeen:any) => {
    let differenceInDays = parseInt(lastSeen)
    if (differenceInDays < 0){
        return "text-blue-500"
    }
    else if (differenceInDays <= 7 && differenceInDays >= 0){
        return "text-green-500"
    }
    else if (differenceInDays <= 30 && differenceInDays > 7){
        return "text-orange-500"
    }
    else if (differenceInDays > 30){
        return "text-red-500"
    }
};
function getBackgroundColor(lastSeen:any){
    let differenceInDays = parseInt(lastSeen)
    if (differenceInDays < 0){
        return "bg-blue-200"
    }
    else if (differenceInDays <= 7 && differenceInDays >= 0){
        return "bg-green-100"
    }
    else if (differenceInDays <= 30 && differenceInDays > 7){
        return "bg-orange-100"
    }
    else if (differenceInDays > 30){
        return "bg-red-100"
    }
}
function getCircularMarkerColor(status:any){
    if(status === "active"){
        return "bg-green-500"
    }
    else if(status === "expired"){
        return "bg-red-500"
    }
    else if(status === "pending"){
        return "bg-blue-500"
    }

}
function getCookie(name:any) {
//    get cookie in react
    const value = `; ${document.cookie}`;
    console.log(value)
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2){
        return parts.pop()?.split(';').shift();
    }
}
export {calculateDifference, getBackgroundColor, getMarkerColor, getCookie,getCircularMarkerColor}